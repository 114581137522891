import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { ConfirmAtom } from 'atoms/confirm';

/**
 * Hook to trigger confirm dialog and get promise which resolves/rejects on user ok/cancel click
 * @returns function
 */
export function useConfirm() {
  const setConfirm = useSetRecoilState(ConfirmAtom);

  return useCallback(
    message =>
      new Promise((resolve, reject) => {
        setConfirm({ message, resolve, reject });
      }),
    [setConfirm],
  );
}
