import React, { memo, useCallback } from 'react';
import Box from '@mui/material/Box';
import { useRecoilState } from 'recoil';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { IconButton, MenuItem, Popover } from '@mui/material';
import { useTheme } from '@mui/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
import PropTypes from 'prop-types';

import { logout } from 'utils/auth';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as LogoMobile } from 'assets/images/logo-mobile.svg';
import { NAVBAR_HEIGHT, NAVBAR_Z_INDEX } from 'common/theme';
import { useIsMobile } from 'hooks/useIsMobile';
import { UserAtom } from 'atoms/user';
import UserPopper from 'components/common/UserPopper';
import { storage } from 'utils/storage';
import { setColorTheme } from 'utils/dom';
import { ModeAtom } from 'atoms/mode';
import { ROUTES } from 'constants/routes';
import { useConfirm } from 'hooks/useConfirm';

const LANGUAGES = ['en', 'de'];

function Navbar({ toolLink }) {
  const { t } = useTranslation();
  const [user, setUser] = useRecoilState(UserAtom);
  const theme = useTheme();
  const [, setMode] = useRecoilState(ModeAtom);
  const isMobileView = useIsMobile();
  const LogoComponent = isMobileView ? LogoMobile : Logo;
  const loggedIn = !!user?.email;
  const confirm = useConfirm();

  const { i18n } = useTranslation('translation');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleMode = useCallback(() => {
    setMode(prev => {
      const newMode = prev === 'light' ? 'dark' : 'light';
      setTimeout(() => setColorTheme(newMode), 0);
      return newMode;
    });
  }, [setMode]);

  const handleLogout = useCallback(() => {
    confirm('auth.messages.logoutConfirmation').then(() => {
      setUser({});
      storage.resetUser();
      logout();
    });
  }, [setUser]);

  const handleLanguageClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { Icon, text } = toolLink || {};
  const toolButton = toolLink ? (
    <Button key={text} color="secondary">
      <Icon />
      &nbsp;
      <Box fontWeight={600}>{t(text)}</Box>
    </Button>
  ) : null;

  const changeLanguage = useCallback(
    event => {
      const { value } = event.currentTarget.dataset || {};
      if (value) {
        i18n.changeLanguage(value);
      }
    },
    [i18n],
  );

  return (
    <>
      <Box width="100%">
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height={NAVBAR_HEIGHT}
          bgcolor="primary.main"
          zIndex={NAVBAR_Z_INDEX}
        >
          <Box position="relative" width="100%">
            <Box height={NAVBAR_HEIGHT} display="flex" alignItems="center">
              <Box ml="6px" height={40}>
                <Link to={ROUTES.dashboard}>
                  <LogoComponent height={40} />
                </Link>
              </Box>
            </Box>
          </Box>
          <Box
            position="absolute"
            top={0}
            right={0}
            p="5px"
            height={NAVBAR_HEIGHT}
            display="flex"
            alignItems="center"
            color="white"
          >
            {loggedIn && <UserPopper user={user} logout={handleLogout} />}
            <IconButton sx={{ ml: 1 }} onClick={toggleMode} color="inherit">
              {theme.palette.mode === 'dark' ? (
                <LightModeIcon color="inherit" />
              ) : (
                <DarkModeIcon color="inherit" />
              )}
            </IconButton>
            <Button
              color="inherit"
              endIcon={<ArrowDropDownRoundedIcon />}
              onClick={handleLanguageClick}
            >
              <b>{i18n.language}</b>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {LANGUAGES.map(lng => (
                <MenuItem
                  key={lng}
                  size="small"
                  data-value={lng}
                  onClick={changeLanguage}
                  style={{ marginRight: 4 }}
                >
                  {lng.toUpperCase()}
                </MenuItem>
              ))}
            </Popover>
          </Box>
        </Box>
      </Box>
      {loggedIn && (
        <Box
          position="fixed"
          top={NAVBAR_HEIGHT}
          width="100%"
          bgcolor="background.default"
          zIndex={NAVBAR_Z_INDEX}
        >
          <Breadcrumbs aria-label="breadcrumb" color="secondary">
            <Button
              key="home"
              component={Link}
              to={ROUTES.dashboard}
              color="secondary"
            >
              <SubscriptionsRoundedIcon />
              &nbsp;&nbsp;
              <Box fontWeight={600}>Booking</Box>
            </Button>
            {toolButton}
          </Breadcrumbs>
        </Box>
      )}
    </>
  );
}

Navbar.propTypes = {
  toolLink: PropTypes.shape({
    to: PropTypes.string,
    icon: PropTypes.node,
    text: PropTypes.string,
  }),
};

export default memo(Navbar);
